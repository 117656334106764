import {
  MachineStatusChoices,
  TargetMemberTypeChoices,
} from "../__generated__/graphql";

export const emptyBehavior = {
  id: "",
  name: "",
  started: "",
  status: "DRAFT" as MachineStatusChoices,
  targetMemberType: "ORG" as TargetMemberTypeChoices,
  audience: JSON.stringify({
    criteria: [
      {
        version: "1",
        type: "filter",
        filter: {
          version: "1",
          filters: {
            operator: "AND",
            conditions: [],
          },
        },
      },
    ],
  }),
  observing: JSON.stringify({ criteria: [] }),
  actionsOnEnter: JSON.stringify({
    delivery: {
      mode: "bulk",
    },
    actions: [],
  }),
  actionsOnCompletion: JSON.stringify({
    delivery: {
      mode: "bulk",
    },
    actions: [],
  }),
  actionsWhileObserving: [],
  deleted: false,
  stats: {},
};
