import { useMemo, useState, useContext } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_LIFECYCLE_STAGES, GET_BEHAVIORS } from "../graphql/queries";
import PageHeader, { Button } from "../patterns/PageHeader";
import EmptyState from "../patterns/EmptyState";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";
import TabNavigation from "../patterns/TabNavigation";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import NewLifecycleStage from "./NewLifecycleStage";
import { SessionContext } from "../SessionContext";
import { DashboardItemList } from "../types/BackendTypes";
import { ORDERED_LIFECYCLE_STAGES, LifecycleStages } from "../types/LocalTypes";
import FlexibleBehaviorCard from "../behavior/FlexibleBehaviorCard";
import PrimaryButton from "../patterns/atoms/PrimaryButton";

export const VIEW_PARAM = "lifecycle_stage";

function lifecycleStageSort(a: any, b: any) {
  const ax = ORDERED_LIFECYCLE_STAGES.findIndex(
    (s) => s.toLowerCase() === a.label.toLowerCase(),
  );
  const bx = ORDERED_LIFECYCLE_STAGES.findIndex(
    (s) => s.toLowerCase() === b.label.toLowerCase(),
  );
  return ax - bx;
}

export default function LifecycleStagesMain() {
  const navigate = useNavigate();
  const session = useContext(SessionContext);
  const [modalIsVisible, setModalVisible] = useState(false);
  const { data, loading, error } = useQuery(GET_LIFECYCLE_STAGES);
  const lifecycleStages = data?.allLifecycleStages;

  const {
    data: behaviorsData,
    loading: behaviorsLoading,
    error: behaviorsError,
  } = useQuery(GET_BEHAVIORS);

  let [searchParams, setSearchParams] = useSearchParams();
  let view = searchParams.get(VIEW_PARAM) ?? LifecycleStages.Onboarding;

  const currentLifecycleStageName = useMemo(() => {
    const currentStage = lifecycleStages?.find((stage) => stage.name === view);
    return currentStage?.name;
  }, [view, lifecycleStages]);

  const currentLifecycleStage = useMemo(() => {
    return lifecycleStages?.find(
      (stage) => stage.name === currentLifecycleStageName,
    );
  }, [lifecycleStages, currentLifecycleStageName]);

  const lifecycleStageTabs = useMemo(
    () =>
      lifecycleStages?.map((stage) => ({
        label: stage.name,
        action: () => setSearchParams({ [VIEW_PARAM]: stage.name }),
        isActive: view === stage.name,
      })) || [],
    [lifecycleStages, setSearchParams, view],
  );
  lifecycleStageTabs?.sort((a, b) => lifecycleStageSort(a, b));

  const addLifecycleStageButton = {
    type: "lifecycle_stage",
    label: "Add a Lifecycle Stage",
    action: () => setModalVisible(true),
  } as Button;

  const dashboardDefinition = useMemo(() => {
    const dashboardConfig = session?.dashboardConfigs.find(
      (dc) => dc.location === "lifecycle_dash_" + currentLifecycleStageName,
    );
    return dashboardConfig
      ? JSON.parse(dashboardConfig?.definition)
      : undefined;
  }, [session, currentLifecycleStageName]);

  if (loading || behaviorsLoading) {
    return <SkeletonScreen />;
  }

  if (error || behaviorsError) {
    return <FullScreenError />;
  }

  const behaviorMap = {};
  behaviorsData.allBehaviors
    .filter((behavior) => !behavior.deleted)
    .forEach((behavior) => {
      behaviorMap[behavior.id] = behavior;
    });

  return (
    <>
      <PageHeader
        header="Lifecycle Stages"
        subhead=""
        buttons={[addLifecycleStageButton]}
      />
      {modalIsVisible && (
        <NewLifecycleStage closeModal={() => setModalVisible(false)} />
      )}
      {lifecycleStages.length === 0 && (
        <EmptyState
          title="What are your customers doing?"
          icon={<AttributesEmpty />}
          description={
            "By setting up Lifecycle Stages, you can build insights over time around what actions your customers are taking"
          }
          buttonLabel={"Add a Lifecycle Stage"}
          onClick={() => setModalVisible(true)}
        />
      )}
      {lifecycleStages.length > 0 && (
        <>
          <TabNavigation tabs={lifecycleStageTabs} />
          {dashboardDefinition ? (
            <div className="px-8 flex flex-col gap-4 w-full">
              {dashboardDefinition.rows.map((row: DashboardItemList, index) => {
                return (
                  <div key={index} className="flex flex-row gap-4 w-full">
                    {row.items.map((itemConfig) => (
                      <FlexibleBehaviorCard
                        behavior={behaviorMap[itemConfig.objectId]}
                        config={itemConfig}
                      />
                    ))}
                  </div>
                );
              })}
              <div className="w-1/4">
                <PrimaryButton
                  label={"Configure Lifecycle Stage"}
                  onClick={() => {
                    navigate(
                      `/lifecycle_stages/${currentLifecycleStage.id}?view=settings`,
                    );
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <EmptyState
                title={`Monitor customer's ${currentLifecycleStageName} activities`}
                icon={<AttributesEmpty />}
                description="Contact us to set up this lifecycle stage dashboard"
                buttonLabel="Configure Lifecycle Stage"
                onClick={() => {
                  navigate(
                    `/lifecycle_stages/${currentLifecycleStage.id}?view=settings`,
                  );
                }}
              />
            </div>
          )}
        </>
      )}
    </>
  );
}
