import { cx } from "../lib/cx";

interface Props {
  bgColor?: string;
}

export default function Card({
  children,
  bgColor = "bg-white",
}: Props & React.PropsWithChildren) {
  return (
    <div className={cx("flex grow flex-col rounded-md p-4", bgColor)}>
      {children}
    </div>
  );
}
