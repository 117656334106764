import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { BehaviorType, MachineStatusChoices } from "../__generated__/graphql";

import CampaignStatusPill from "../campaigns/CampaignStatePill";
import Table, { TableProps } from "../patterns/tables/table";

interface Props {
  objectives: BehaviorType[];
}

function toTable(
  objectives: BehaviorType[],
  navigate: NavigateFunction,
): Omit<TableProps, "filterBar"> {
  return {
    header: [
      { label: "Name", width: "w-1/5" },
      { label: "Status" },
      { label: "Entered", width: "w-1/5" },
      { label: "Completed", width: "w-1/5" },
      { label: "Average time to completion", width: "w-1/5" },
    ],
    rows: objectives
      .filter((f) => !f.deleted)
      .map((b) => {
        return {
          id: b.id,
          onClick: () => navigate(`/behaviors/${b.id}`),
          cells: [
            {
              content: (
                <div className="flex flex-col min-w-80">
                  <Link to={`/behaviors/${b.id}`}>
                    <span className="text-sm font-medium tracking-tight text-body-text">
                      {b.name}
                    </span>
                  </Link>
                </div>
              ),
            },
            {
              content: <CampaignStatusPill status={b.status} />,
            },
            {
              content: (
                <div className="my-auto mr-4 h-8 flex flex-col">
                  {b.status === MachineStatusChoices.Draft ? (
                    "-"
                  ) : (
                    <>
                      <span className="font-bold text-sm text-body-text">
                        {b.stats.entered.count}
                      </span>
                      <span className="text-body-text-lightest">
                        (
                        {Math.round(
                          (b.stats.entered.count /
                            b.stats.totalPossibleMembers) *
                            100,
                        )}
                        %)
                      </span>
                    </>
                  )}
                </div>
              ),
            },
            {
              content: (
                <div className="my-auto mr-4 h-8 flex flex-col">
                  {b.status === MachineStatusChoices.Draft ? (
                    "-"
                  ) : (
                    <>
                      <span className="font-bold text-sm text-body-text">
                        {b.stats.completed.count}
                      </span>
                      <span className="text-body-text-lightest">
                        (
                        {b.stats.completed.count > 0
                          ? Math.round(
                              (b.stats.completed.count /
                                b.stats.totalPossibleMembers) *
                                100,
                            )
                          : "0"}
                        %)
                      </span>
                    </>
                  )}
                </div>
              ),
            },
            {
              content: (
                <div className="my-auto mr-4 h-8 flex flex-col">
                  {b.status === MachineStatusChoices.Draft ? (
                    "-"
                  ) : (
                    <>
                      <span className="font-bold text-sm text-body-text">
                        {Math.round(b.stats.timeToCompletion.average / 86400)}{" "}
                      </span>
                      <span className="text-body-text-lightest">days</span>
                    </>
                  )}
                </div>
              ),
            },
          ],
        };
      }),
  };
}

export default function ObjectiveTable({ objectives }: Props) {
  const navigate = useNavigate();
  const tableDefinition = toTable(objectives, navigate);

  return <Table header={tableDefinition.header} rows={tableDefinition.rows} />;
}
