import "../loading.css";
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import toast from "react-hot-toast";
import { NetworkStatus, useApolloClient, useLazyQuery } from "@apollo/client";
import { GET_PEOPLE, GET_AI_FILTER_GROUPS } from "../graphql/queries";
import { Flags, TargetMemberTypeChoices } from "../__generated__/graphql";
import { pluralize } from "../lib/string";
import { isEmptyFilterDoc } from "../lib/filters";
import { filterDocIsComplete } from "../filter_builder/lib";

import useIsFeatureEnabled from "../hooks/useIsFeatureEnabled";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import useQueryAndFilter from "../hooks/useQueryAndFilter";

import EmptyState from "../patterns/EmptyState";
import PeopleEmpty from "../patterns/illustrations/PeopleEmpty";
import PageHeader from "../patterns/PageHeader";
import FullScreenError from "../patterns/Errors";
import SkeletonScreen from "../patterns/Skeleton";
import Alert from "../patterns/Alert";
import PrimaryButton from "../patterns/atoms/PrimaryButton";
import NewPersonModal from "./NewPersonModal";
import NewBehavior from "../behaviors/NewBehavior";
import PeopleTable from "./table";

const PAGE_SIZE = 50;

const SEARCH_DEBOUNCE_TIMEOUT = 1000;

export default function PeopleMain() {
  const canUseAIFilter = useIsFeatureEnabled(Flags.AiFilterGroups);
  const client = useApolloClient();
  const [isPaginating, setIsPaginating] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [behaviorModalIsVisible, setBehaviorModalIsVisible] = useState(false);
  const { query, updateQuery, filter, updateFilter } = useQueryAndFilter();
  const [
    getPeople,
    { error, data, fetchMore, previousData, networkStatus, refetch },
  ] = useLazyQuery(GET_PEOPLE, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [aiFilterPrompt, setAiFilterPrompt] = useState("");
  const [aiQueryErrors, setAiQueryErrors] = useState([]);
  const [aiFilterLoading, setAiFilterLoading] = useState(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  // Initial fetch is not debounced
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      getPeople({ variables: { filter: JSON.stringify(filter), query } });
    }
  }, []);

  const debouncedRefetch = useCallback(
    debounce(refetch, SEARCH_DEBOUNCE_TIMEOUT),
    [refetch],
  );
  /* eslint-enable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (filterDocIsComplete(filter)) {
      debouncedRefetch({ filter: JSON.stringify(filter), query });
    }
  }, [filter, query, debouncedRefetch]);

  const loadNext = useCallback(async () => {
    setIsPaginating(true);
    try {
      await fetchMore({
        variables: {
          cursor: data.allPeople.edges[data.allPeople.edges.length - 1].node.id,
          filter: JSON.stringify(filter),
          query,
          limit: PAGE_SIZE,
        },
      });
    } finally {
      setIsPaginating(false);
    }
  }, [filter, query, data, fetchMore]);

  const nextPageTrigger = useInfiniteScroll(loadNext);

  const getFilterGroups = useCallback(async () => {
    if (!aiFilterPrompt) return null;

    setAiQueryErrors([]);
    setAiFilterLoading(true);
    let response;
    try {
      response = await client.query({
        query: GET_AI_FILTER_GROUPS,
        variables: { query: aiFilterPrompt },
      });
    } catch (e) {
      toast.error(
        "Something went wrong with that query. Please try again, using more specific terms.",
      );
    } finally {
      if (response?.data?.aiFilterGroups.errors.length > 0) {
        setAiQueryErrors(response?.data?.aiFilterGroups.errors);
      } else if (response?.data?.aiFilterGroups.filters) {
        updateFilter(JSON.parse(response?.data?.aiFilterGroups.filters));
      }
    }
    setAiFilterLoading(false);
  }, [client, aiFilterPrompt, updateFilter, setAiQueryErrors]);

  if (error) {
    return <FullScreenError />;
  }

  if (!previousData && !data) {
    return <SkeletonScreen />;
  }

  const currentData = data || previousData;

  const loading = networkStatus === NetworkStatus.setVariables;

  const showEmptyState =
    currentData.allPeople.total === 0 && isEmptyFilterDoc(filter);

  const hasMore =
    currentData.allPeople.total > currentData.allPeople.edges.length;

  return (
    <>
      {isModalVisible && (
        <NewPersonModal closeModal={() => setIsModalVisible(false)} />
      )}
      {behaviorModalIsVisible && (
        <NewBehavior
          closeModal={() => setBehaviorModalIsVisible(false)}
          audienceCriteria={filter}
          predefinedTargetMemberType={TargetMemberTypeChoices.Person}
        />
      )}
      <div className="flex flex-col max-w-8xl mx-auto mt-0 2xl:mt-8">
        {showEmptyState ? (
          <EmptyState
            title="This is where you'll find your users"
            description="You’ll be able to filter and sort this list, or use it as the audience
        for future campaigns "
            icon={<PeopleEmpty />}
            buttonLabel={"Add a person"}
            onClick={() => setIsModalVisible(true)}
          />
        ) : (
          <div className="flex flex-col pb-10">
            <PageHeader
              header="People"
              subhead={
                loading ? "…" : pluralize(currentData.allPeople.total, "person")
              }
              buttons={[
                {
                  type: "primary" as const,
                  label: "Add a person",
                  action: () => {
                    setIsModalVisible(true);
                  },
                },
              ]}
            />
            {canUseAIFilter && (
              <div className="mx-8 mb-6 p-2 rounded-xl bg-white">
                <div className="flex flex-row gap-2 items-center align-top mb-2">
                  <input
                    placeholder="Show me recently active people that have a revenue over $10,000"
                    value={aiFilterPrompt}
                    onChange={(e) => setAiFilterPrompt(e.target.value)}
                    required={false}
                    type="text"
                    className={`block w-full rounded-lg border ${error ? "border-red" : "border-rule-color"} bg-white text-xs font-normal leading-6 tracking-wide text-body-text focus:border-blue-800 focus:ring-blue-300 p3`}
                    aria-describedby="helper-text-explanation"
                  />
                  {aiFilterLoading ? (
                    <div className="spinner"></div>
                  ) : (
                    <PrimaryButton
                      label={"Apply"}
                      onClick={getFilterGroups}
                      isDisabled={aiFilterPrompt === "" || aiFilterLoading}
                    />
                  )}
                </div>
                {aiQueryErrors.length > 0 && (
                  <Alert
                    message="There was an error with your filter"
                    type="error"
                  >
                    <ul className="mt-2">
                      {aiQueryErrors?.map((e) => (
                        <li key={e} className="pl-4 text-xs">
                          "{e.snippet}" - {e.message}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                )}
              </div>
            )}
            <PeopleTable
              people={currentData.allPeople.edges}
              filter={filter}
              query={query}
              updateFilter={updateFilter}
              updateQuery={updateQuery}
              showNewBehaviorModal={setBehaviorModalIsVisible}
              isLoading={loading}
            />
            {hasMore && <div ref={nextPageTrigger}></div>}
            {isPaginating ? (
              <SkeletonScreen />
            ) : (
              <div className="w-full min-h-96"></div>
            )}
          </div>
        )}
      </div>
    </>
  );
}
