import { useContext, useMemo } from "react";
import { LifecycleStageType } from "../__generated__/graphql";

import { SessionContext } from "../SessionContext";
import FlexibleBehaviorCard from "../behavior/FlexibleBehaviorCard";
import { DashboardItemList } from "../types/BackendTypes";
import { useQuery } from "@apollo/client";
import { GET_BEHAVIORS } from "../graphql/queries";
import SkeletonScreen from "../patterns/Skeleton";
import FullScreenError from "../patterns/Errors";
import EmptyState from "../patterns/EmptyState";
import AttributesEmpty from "../patterns/illustrations/AttributesEmpty";

export default function LifecycleStageOverview({
  lifecycleStage,
}: {
  lifecycleStage: LifecycleStageType;
}) {
  const session = useContext(SessionContext);
  const {
    data: behaviorsData,
    loading: behaviorsLoading,
    error: behaviorsError,
  } = useQuery(GET_BEHAVIORS);

  const dashboardDefinition = useMemo(() => {
    const dashboardConfig = session?.dashboardConfigs.find(
      (dc) => dc.location === "lifecycle_objective_dash_" + lifecycleStage.name,
    );
    return dashboardConfig
      ? JSON.parse(dashboardConfig?.definition)
      : undefined;
  }, [session, lifecycleStage]);

  const behavior = lifecycleStage.behavior;
  if (!behavior)
    return (
      <div className="grid py-24 items-center text-center">
        Lifecycle Stage: {lifecycleStage.name} has no associated Behavior
      </div>
    );

  if (behaviorsLoading) {
    return <SkeletonScreen />;
  }

  if (behaviorsError) {
    return <FullScreenError />;
  }

  const behaviorMap = {};
  behaviorsData.allBehaviors
    .filter((behavior) => !behavior.deleted)
    .forEach((behavior) => {
      behaviorMap[behavior.id] = behavior;
    });

  return (
    <>
      {dashboardDefinition ? (
        <div className="px-8 flex flex-col gap-4 w-full">
          {dashboardDefinition.rows.map((row: DashboardItemList, index) => {
            return (
              <div key={index} className="flex flex-row gap-4 w-full">
                {row.items.map((itemConfig) => (
                  <FlexibleBehaviorCard
                    behavior={behaviorMap[itemConfig.objectId]}
                    config={itemConfig}
                  />
                ))}
              </div>
            );
          })}
        </div>
      ) : (
        <EmptyState
          title={`Monitor the ${lifecycleStage.name} Lifecycle Stage objectives`}
          icon={<AttributesEmpty />}
          description="Contact us to set up this lifecycle stage objective dashboard"
        />
      )}
    </>
  );
}
